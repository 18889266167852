<template>
  <div>
    <v-card-text>
      <v-row>
        <v-col
          v-if="permisos.includes('user_register:create') || permisos.includes('*')"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click="newItem"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t('btn.insert') }}</span>
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>

        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify"
            :label="$t('btn.search')"
            single-line
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-uppercase">
              {{ $t('lbl.company') }}
            </th>
            <!--<th class="text-uppercase">
              {{ $t('lbl.user') }}
            </th>-->
            <th class="text-uppercase">
              {{ $t('lbl.contacts') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.profiles') }}
            </th>
            <!--<th class="text-uppercase">
              {{ $t('menu.products') }}
            </th>-->
            <th class="text-uppercase">
              {{ $t('lbl.dateSoliciti') }}
            </th>
            <th class="text-uppercase text-center">
              {{ $t('lbl.state') }}
            </th>
            <th
              width="5%"
              class="text-uppercase text-center"
            >
              {{ $t('lbl.code') }}
            </th>
            <th
              v-if="permisos.includes('user_register:edit') || permisos.includes('*')"
              class="text-uppercase"
              style="text-align: center;"
            >
              {{ $t('lbl.actions') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(iten, index) in items"
            :key="index"
          >
            <td>
              {{ iten.name_comercial }}
            </td>
            <!--<td>
              {{ iten.name_contact }}<br />
              {{ iten.email_contact }}
            </td>-->
            <td>
              <v-tooltip
                v-if="iten.contacts.length > 0"
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiInformationOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>
                  <v-card class="mx-auto">
                    <v-card-text v-if="iten.contacts.length > 0">
                      <strong>{{ $t('lbl.contacts') }}</strong>
                      <v-divider class="mb-2 mt-2"></v-divider>
                      <p>
                        <span
                          v-for="(contact, i) in iten.contacts"
                          :key="Math.random() * (i + 1)"
                        >
                          <b>{{ $t('lbl.name') }}</b>: {{ contact.name }}<br />
                          <!--<b>{{ $t('lbl.rol') }}</b>:
                          <span v-if="contact.role_id !== null && contact.role_id !== 'null'">
                            <span v-if="rols.filter(e => e.id === contact.role_id).length > 0">
                              {{ rols.filter(e => e.id === contact.role_id)[0].name }}
                            </span>
                          </span>
                          <br />-->
                          <b>{{ $t('lbl.email') }}</b>:
                          <br />
                          <span
                            v-for="(email, indE) in contact.emails"
                            :key="Math.random() * (indE + 1)"
                          >
                            {{ email.email }}<br />
                          </span>
                          <b>{{ $t('menu.products') }}</b> <br />
                          <span
                            v-for="(prod, indP) in contact.products"
                            :key="Math.random() * (indP + 1)"
                          >
                            <span v-if="products.filter(e => e.id === prod).length > 0">
                              {{ products.filter(e => e.id === prod)[0].name }} <br />
                            </span>
                          </span>

                          <b>{{ $t('lbl.telefonos') }}</b> <br />
                          <span
                            v-for="(telefono, indP) in contact.telefonos"
                            :key="Math.random() * (indP + 1)"
                          >
                            {{ telefono.telefono !== null ? telefono.telefono : '' }}
                            {{ telefono.ext !== null ? `Ext: ${telefono.ext}` : '' }}
                            <br />
                          </span>
                          <v-divider
                            v-if="i < iten.contacts.length - 1"
                            class="mb-2 mt-2"
                          ></v-divider>
                        </span>
                      </p>
                    </v-card-text>
                  </v-card>
                </span>
              </v-tooltip>
            </td>
            <td>
              <span
                v-for="(perfil, i) in iten.perfiles"
                :key="i"
              > {{ perfil.name }}<br /> </span>
              <!--<span v-if="iten.is_client_corporate">
                {{ $t('lbl.clientCorporate') }}
              </span>-->
            </td>
            <!--<td>
              <span
                v-for="(product, i) in products"
                :key="i"
              >
                <v-checkbox
                  v-model="iten.nom_tipo_products_id"
                  :disabled="(!permisos.includes('user_register:edit') && !permisos.includes('*')) || !iten.procesada"
                  :label="product.name"
                  :value="product.id"
                  hide-details
                  @change="changeProduct(iten.id, iten.nom_tipo_products_id)"
                ></v-checkbox>
              </span>
            </td>-->
            <td>
              {{ iten.fecha_registro ? $moment(iten.fecha_registro).format('D-MMM-YY') : '' }}
            </td>
            <td class="text-center">
              <!--<v-switch
                v-if="iten.aprobada"
                v-model="iten.active"
                :disabled="!permisos.includes('user_register:edit') && !permisos.includes('*')"
                @change="editActive(iten)"
              ></v-switch>-->
              <span v-if="iten.offline">
                <v-chip
                  color="info"
                  text-color="white"
                >
                  Offline
                </v-chip>
              </span>
              <span v-else-if="iten.aprobada && iten.active">Activo</span>
              <span v-else-if="iten.aprobada && !iten.active">Inactivo</span>
              <span v-else-if="!iten.aprobada && iten.procesada && !iten.active">Denegado</span>
              <span v-else-if="!iten.procesada">
                <v-chip
                  color="red"
                  text-color="white"
                >
                  Pendiente
                </v-chip>
              </span>
            </td>
            <td>{{ iten.code_unique }}</td>
            <td
              v-if="permisos.includes('user_register:edit') || permisos.includes('*')"
              style="text-align: center;"
            >
              <!--<v-tooltip
                v-if="iten.procesada"
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiEye }}</v-icon>
                  </v-btn>
                </template>
                <span>
                  <v-card class="mx-auto">
                    <v-card-text>
                      <strong>{{ $t('register.titlePerson') }}</strong>
                      <p class="text--primary">
                        {{ $t('register.namePerson') }}: <b>{{ iten.name_contact }} {{ iten.apellidos_contact }}</b><br />
                        {{ $t('register.emailPerson') }}:
                        <a :href="`mailto:${iten.name_contact}`">
                          <i>{{ iten.email_contact }}</i> </a><br />
                        {{ $t('register.noTelefo') }}: <b>{{ iten.prefijo_number_contact }} {{ iten.number_contact }}</b><br />
                        {{ $t('register.nameCompany') }}: <b>{{ iten.name_comercial }}</b><br />
                        <a
                          v-if="iten.site_web"
                          :href="iten.site_web"
                          target="_blank"
                        ><i>{{ iten.site_web }}</i></a>
                      </p>
                    </v-card-text>
                    <v-card-text>
                      <strong>{{ $t('register.titleFact') }}</strong>
                      <p>
                        {{ $t('register.nameFact') }}: <b>{{ iten.name_fiscal_facturation }}</b><br />
                        {{ $t('register.tipoFiscal') }}: <b>{{ iten.tipo_fiscal }}</b><br />
                        {{ $t('register.idFiscal') }}: <b>{{ iten.cif_nif_facturation }}</b><br />
                        {{ $t('register.noBanco') }}: <b>{{ iten.no_cuenta_bancaria }}</b><br />
                        {{ $t('register.tipoBanco') }}: <b>{{ iten.tipo_cuenta_bancaria }}</b><br />
                        {{ $t('register.moneda') }}: <b>{{ iten.moneda }}</b><br />
                        {{ $t('register.nameBanco') }}: <b>{{ iten.name_banco }}</b><br />
                        {{ $t('register.codeSWIFT') }}: <b>{{ iten.swift }}</b><br />
                        {{ $t('register.codeSucursal') }}: <b>{{ iten.code_sucursal }}</b><br />
                        {{ $t('register.address') }}: <b>{{ iten.direction_facturation }}</b><br />
                        {{ $t('register.locality') }}: <b>{{ iten.localidad_facturation }}</b><br />
                        {{ $t('register.country') }}: <b>{{ iten.country_facturation }}</b><br />
                      </p>
                    </v-card-text>
                    <v-card-text v-if="iten.nom_tipo_products.length > 0">
                      <strong>{{ $t('menu.products') }}</strong>
                      <p>
                        <span
                          v-for="(product, i) in iten.nom_tipo_products"
                          :key="i"
                        > {{ product.name }}<br /> </span>
                      </p>
                    </v-card-text>
                  </v-card>
                </span>
              </v-tooltip>-->

              <v-menu
                v-if="permisos.includes('user_register:edit') || permisos.includes('*')"
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-if="(permisos.includes('user_register:edit') || permisos.includes('*'))
                      && (!iten.procesada || (!iten.aprobada && iten.procesada && !iten.active))"
                    link
                    @click="editItem(iten)"
                  >
                    <v-list-item-title>
                      <v-btn icon>
                        <v-icon>{{ icons.mdiCheckboxIntermediate }}</v-icon>
                      </v-btn>
                      <span>{{ $t('btn.edit') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="(permisos.includes('user_register:edit') || permisos.includes('*'))
                      && iten.aprobada && iten.procesada && iten.active
                    "
                    link
                    @click="updateItem(iten)"
                  >
                    <v-list-item-title>
                      <v-btn icon>
                        <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                      </v-btn>
                      <span>{{ $t('btn.edit') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="
                      (permisos.includes('user_register:destroy') || permisos.includes('*')) &&
                        iten.slug !== 'iberostar' &&
                        iten.slug !== 'melia' &&
                        iten.slug !== 'blue-diamond'
                    "
                    link
                    @click="deleteItem(iten)"
                  >
                    <v-list-item-title>
                      <v-btn icon>
                        <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                      </v-btn>
                      <span>{{ $t('btn.delete') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card-text class="pt-2">
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-end"
        >
          <v-pagination
            v-model="pagination.current"
            :length="pagination.total"
            total-visible="5"
            @input="onPageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')} ${itemDelete.name_comercial}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.deleteItem', { itemDelete: item.name_comercial }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiCheckboxIntermediate,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiEye,
  mdiInformationOutline,
  mdiDotsVertical,
} from '@mdi/js'

export default {
  data() {
    return {
      search: '',
      items: [],
      products: [],
      pagination: {
        current: 1,
        total: 0,
      },
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiCheckboxIntermediate,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiEye,
        mdiInformationOutline,
        mdiDotsVertical,
      },
      isDialogVisible: false,
      item: {},
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      isLoad: true,
      permisos: sessionStorage.getItem('permisos_auth'),
      itemDelete: {},
      isDialogVisibleDelete: false,
      rols: [],
      loading: false,
    }
  },
  mounted() {
    this.getProducts()
    this.getRols()
    this.load()
  },
  methods: {
    ...mapMutations(['updateContactsAfiliados']),
    getProducts() {
      this.axios
        .get('type_product?per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.products = res.data.data
        })
    },
    getRols() {
      this.axios
        .get('role?per_page=10000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.rols = res.data.data
          }
        })
    },
    load() {
      const json = {
        page: this.pagination.current,
        per_page: this.itemsPerPage,
        search: this.search,
      }
      this.axios
        .post('affiliate/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.items = res.data.data
            this.pagination.current = res.data.meta.current_page
            this.pagination.total = res.data.meta.last_page
          }
        })
    },
    onPageChange() {
      this.load()
    },
    showModal(item) {
      this.item = item
      this.isLoad = true
      if (item.permisos !== null) {
        this.abilities = item.permisos.abilities
      } else {
        this.abilities = []
      }
      this.loadPermisos()
      this.isDialogVisible = !this.isDialogVisible
    },
    loadPermisos() {
      this.abilityList = []
      this.axios
        .get('permiso?per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const items = res.data.data
          // eslint-disable-next-line no-plusplus
          for (let indexA = 0; indexA < this.abilities.length; indexA++) {
            const ability = this.abilities[indexA]
            if (ability === '*') {
              // eslint-disable-next-line no-plusplus
              for (let index = 0; index < items.length; index++) {
                const element = items[index]
                this.abilityList.push(element.name)
              }
            } else {
              // eslint-disable-next-line no-plusplus
              for (let index = 0; index < items.length; index++) {
                const element = items[index]
                if (element.ability === ability) {
                  this.abilityList.push(element.name)
                }
              }
            }
          }
          // eslint-disable-next-line no-return-assign
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoad = false))
    },
    newItem() {
      this.updateContactsAfiliados([])
      sessionStorage.removeItem('user-register-id')
      this.$router.push({ name: 'user-register-insert' })
    },
    closeDialog() {
      this.isDialogVisible = !this.isDialogVisible
    },
    closeDialogDelete() {
      this.isDialogVisibleDelete = !this.isDialogVisibleDelete
      this.itemDelete = {}
    },
    editItem(iten) {
      this.updateContactsAfiliados([])
      sessionStorage.setItem('user-register-id', iten.id)
      this.$router.push({ name: 'user-register-update' })
    },
    editActive(iten) {
      this.axios
        .post(
          `affiliate/active/${iten.id}`,
          { active: iten.active },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          }
        })
    },

    updateItem(iten) {
      this.updateContactsAfiliados([])
      sessionStorage.setItem('user-register-id', iten.id)
      this.$router.push({ name: 'user-register-insert' })
    },

    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.itemDelete = iten
    },
    confirmDelete() {
      this.loading = true
      this.axios

      // .delete(`affiliate/${this.itemDelete.id}`, {

        .post('affiliate/change-status', { id: this.itemDelete.id, offline: false }, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            if (res.data.data.status === 301) {
              this.$toast.error(this.$t('msg.notDeleteItemRelationsContrate', { contrate: 'Hoteles' }))
            } else if (res.data.data.status === 302) {
              this.$toast.error(this.$t('msg.notDeleteItemRelationsContrate', { contrate: 'Autos' }))
            } else if (res.data.data.status === 305) {
              this.$toast.error(this.$t('msg.notDeleteItemRelationsMarcaAsociada', { item: 'Condiciones generales' }))
            } else if (res.data.data.status === 306) {
              this.$toast.error(this.$t('msg.notDeleteItemRelationsAgents', { item: 'Agentes' }))
            } else {
              // this.$toast.error(this.$t('msg.noAutorice'))
            }

            this.loading = false

            // this.$store.commit('logout')
          } else {
            this.load()
            this.$toast.success(this.$t('msg.infoSuccess'))
            this.loading = false
          }
        })
        .finally(() => this.closeDialogDelete())
    },

    changeProduct(itenId, products) {
      this.axios.post(
        `affiliate/products/${itenId}`,
        { products },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        },
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
